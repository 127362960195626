import React from "react"
import ReactPlayer from 'react-player/lazy'
import AudioPlayer from 'react-h5-audio-player'
import { analyticClickEvent } from "../components/googleAnalytics"
import { useIdentityContext } from 'react-netlify-identity-gotrue'

const ReactVideoPlayer = ( {videoURL, placeholder, autoPlay, title, download} ) => {
  const identity = useIdentityContext()
  let action
  if(identity.user){
    action = 'UserID: ' + identity.user.id + ' Watches'
  } else {
    action = 'UserID: NOT LOGGED IN Watches'
  }
  return (
    <ReactPlayer 
    width="640" height="360"
      className='react-player'
      url={videoURL} 
      controls={true}
      playing={false}
      config={{ file: { attributes: { controlsList: download ? 'download' :'nodownload' } } }}
      onContextMenu={e => e.preventDefault()}
      onPlay={e => analyticClickEvent(action,'Video','Play: '+ title)} 
      onPause={e => analyticClickEvent(action,'Video','Pause: '+ title)} 
      onEnded={e => analyticClickEvent(action,'Video','Ended: '+ title)} 
      light={placeholder}
    />
  )
}

const ReactAudioPlayer = ( {audioURL, placeholder, autoPlay, title} ) => {
  const identity = useIdentityContext()
  let action
  if(identity.user){
    action = 'UserID: ' + identity.user.id + ' Listens'
    // const userID = identity.user.id
  } else {
    action = 'UserID: NOT LOGGED IN Listens ' + title
  }
  return (
    <AudioPlayer 
      src={audioURL}
      autoPlay={autoPlay ? true : false}
      autoPlayAfterSrcChange={false}
      onPlay={e => analyticClickEvent(action,'Audio','Play: '+ title, '')} 
      onPause={e => analyticClickEvent(action,'Audio','Pause: '+ title, '')} 
      onEnded={e => analyticClickEvent(action,'Audio','Ended: '+ title, '')}  
      showJumpcontrols={false}
    />
  )
}


export {
  ReactVideoPlayer,
  ReactAudioPlayer
}
